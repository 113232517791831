import style from '../style/Rule.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {
	ruleActions,
	ruleErrorSelector,
	ruleFilterSelector,
	ruleItemsSelector,
	ruleLoadingSelector,
	rulePaginationSelector
} from "../RuleSlice";
import {notification} from "antd";
import ActionBar from "../../../layout/ActionBar";
import {
	Button,
	Icon,
	Loading,
	Modal
} from "../../../components";
import {
	RuleFormAdd,
	RuleFormEdit,
	RuleGroupList,
	RuleItemInfo
} from "../components";
import className from "classnames/bind";
import {apiError, handleRequest} from "../../../utils";
import {ruleApi} from "../../../api";
import {useCan} from "../../../hooks";

const cn = className.bind(style);

function Rules() {

	const can = {
		add: useCan('rulesAdd'),
		edit: useCan('rulesEdit'),
		delete: useCan('rulesDelete'),
	};

	const dispatch  = useDispatch();

	const items     = useSelector(ruleItemsSelector);

	const loading   = useSelector(ruleLoadingSelector);

	const error     = useSelector(ruleErrorSelector);

	const pagination = useSelector(rulePaginationSelector);

	const filter    = useSelector(ruleFilterSelector);

	const [itemEdit, setItemEdit]   = useState({});

	const [ruleGroup, setRuleGroup]     = useState(0);

	//Model
	const [openModal, setOpenModal] = useState({
		addItem         : false,
		editItem        : false,
		deleteItem      : false,
		infoItem        : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		handleReLoading();
	}, [filter]);

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	const handleReLoading = () => {
		let newFilter = {...filter}
		dispatch(ruleActions.fetchData(newFilter));
	}

	const handleAdd = async (data, group) => {

		if (group?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin nhóm quy định để thêm quy định'});
			return;
		}

		data.groupId = group.id;

		let [error, response] = await handleRequest(ruleApi.addItem(data));

		let message = apiError(`Thêm mới quy định thất bại`, error, response);

		if(!message) {
			notification.success({message: 'Thành công', description: `Thêm mới quy định thành công`});
			let newProject = JSON.parse(JSON.stringify(group));
			newProject.items.unshift({...response.data.item})
			setRuleGroup(newProject);
			dispatch(ruleActions.update(newProject));
			handleModalClose('addItem')
		}
	}

	const handleEdit = async (data, ruleItem, group) => {

		if (ruleItem?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin nội quy để cập nhật'});
			return;
		}

		data.id = ruleItem.id;

		let [error, response] = await handleRequest(ruleApi.updateItem(data));
		let message = apiError(`Cập nhật quy định thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật quy định thành công`});
			let newProject = JSON.parse(JSON.stringify(group));
			for (const [index, item] of Object.entries(newProject.items)) {
				if(item.id === ruleItem.id) {
					if(response.data?.name) newProject.items[index].name = response.data.name;
					if(response.data?.content) newProject.items[index].content = response.data.content;
				}
			}
			dispatch(ruleActions.update(newProject));
			handleModalClose('editItem')
		}
	}

	//Delete
	const handleDelete = async (item, group) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin quy định để xóa'});
			return;
		}
		let [error, response] = await handleRequest(ruleApi.deleteItem(item.id));
		let message = apiError(`xóa quy định thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `xóa quy định thành công`});

			let newProject = JSON.parse(JSON.stringify(group));
			newProject.items = newProject.items.filter(function(rule) {
				return item.id !== rule.id
			})
			setRuleGroup(newProject);
			dispatch(ruleActions.update(newProject));
			handleModalClose('deleteItem')
		}
	}


	return (
		<>
			<ActionBar title={'Quy định'}>
				<Button outline small onClick={handleReLoading} style={{fontSize:'20px'}}>{Icon.reload}</Button>
			</ActionBar>
			<div className="container">
				<div className={cn('wrapper', 'pd-1')}>
					{loading && <Loading/>}
					{items && <RuleGroupList
						items={items}
						pagination={pagination}
						setItemEdit={setItemEdit}
						setRuleGroup={setRuleGroup}
						openModal={handleModalOpen}
						closeModal={handleModalClose}
						can={can}
					/>}
				</div>
				{
					(ruleGroup?.id && openModal.addItem) && <Modal title="Thêm quy định" size={'xl'} visible={openModal.addItem} onCancel={() => {handleModalClose('addItem')}}>
						<RuleFormAdd group={ruleGroup} modalOpen={handleModalOpen} onHandleSubmit={handleAdd} />
					</Modal>
				}
				{
					(ruleGroup?.id && itemEdit?.id && openModal.infoItem) && <Modal title="Chi tiết nội quy" size={'xl'} visible={openModal.infoItem} onCancel={() => {handleModalClose('infoItem')}}>
						<RuleItemInfo group={ruleGroup} item={itemEdit} can={can} modalOpen={handleModalOpen} modalClose={handleModalClose} />
					</Modal>
				}
				{
					(ruleGroup?.id && openModal.editItem) && <Modal title="Cập nhật quy định" size={'xl'} visible={openModal.editItem} onCancel={() => {handleModalClose('editItem')}}>
						<RuleFormEdit group={ruleGroup} item={itemEdit} onHandleSubmit={handleEdit} />
					</Modal>
				}
				{
					(itemEdit?.id && openModal.deleteItem) && <Modal title="Xóa quy định" visible={openModal.deleteItem} onCancel={() => {handleModalClose('deleteItem')}}>
						<p>Bạn muốn xóa <b>{itemEdit?.name}</b>?</p>
						<div className="d-flex justify-content-end modal-bottom pd-2">
							<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('deleteItem')}}> Đóng </Button>
							<Button primary leftIcon={Icon.delete} onClick={() => handleDelete(itemEdit, ruleGroup)}> Xóa </Button>
						</div>
					</Modal>
				}
			</div>
		</>
	)
}

export default Rules;