import {memo, useContext} from "react";
import WorkingDayAction from "./WorkingDayAction";
import {StatusLabel, Image, FontAwesomeIcon, Icon} from "~/components";
import {Pagination, Table, Tooltip} from "antd";
import {useDevice, useUtilities} from "~/hooks";
import dayjs from "dayjs";
import {AppContext} from "~/context/AppProvider";

function WorkingDayTable({filter, items = [], pagination, setItemEdit, onPaginationChange, setOpenModal}) {

	const {tableHeight} = useContext(AppContext);

	const {utilities} = useUtilities();

	const {isMobile} = useDevice();

	const renderNumberWorking = (number = 0) => {

		if(isNaN(number)) number = parseInt(number);

		const style = {fontSize:'15px'}

		if(number <= 2.9) {
			return number
		}
		else if(number <= 3) {
			return <StatusLabel bold type="yellow" style={style}>{number}</StatusLabel>
		}
		else if(number > 3) {
			return <StatusLabel bold type="red" style={style}>{number}</StatusLabel>
		}
		return <StatusLabel bold type="green">{number}</StatusLabel>
	}

	const renderIconWorking = (type) => {

		if(type == 'workOnTime') {
			return <Tooltip title="Đúng giờ"><StatusLabel bold type="green">{Icon.success}</StatusLabel></Tooltip>
		}
		if(type == 'goLate') {
			return <Tooltip title="Đi trể"><StatusLabel bold background type="yellow"><FontAwesomeIcon icon="fa-light fa-person-running" /></StatusLabel></Tooltip>
		}
		if(type == 'goSoon') {
			return <Tooltip title="Về sớm"><StatusLabel bold background type="yellow"><FontAwesomeIcon icon="fa-light fa-person-running" /></StatusLabel></Tooltip>
		}
		if(type == 'halfDayOff') {
			return <Tooltip title="Nghỉ nữa ngày nữa ngày làm ở công ty"><StatusLabel bold type="red"><FontAwesomeIcon icon="fa-light fa-minus" /></StatusLabel></Tooltip>
		}

		if(type == 'halfDayOffNo') {
			return <Tooltip title="Nghỉ nữa ngày không phép nữa ngày làm ở công ty"><StatusLabel bold type="red"><FontAwesomeIcon icon="fa-light fa-minus" /></StatusLabel></Tooltip>
		}

		if(type == 'halfPaidHoliDays') {
			return <Tooltip title="Nghỉ nữa ngày (phép năm) nữa ngày làm ở công ty"><StatusLabel bold type="red"><FontAwesomeIcon icon="fa-light fa-minus" /></StatusLabel></Tooltip>
		}

		if(type == 'dayOff') {
			return <Tooltip title="Nghỉ phép"><StatusLabel background type="red">{Icon.close}</StatusLabel></Tooltip>
		}

		if(type == 'dayOffNo') {
			return <Tooltip title="Nghỉ không phép"><StatusLabel background type="red"><FontAwesomeIcon icon="fa-duotone fa-circle-xmark" /></StatusLabel></Tooltip>
		}
		if(type == 'halfDayOffHoliDayHalfDayOff') {
			return <Tooltip title="Nghỉ nữa ngày (phép năm) nữa ngày nghĩ bình thường"><StatusLabel background type="red"><FontAwesomeIcon icon="fa-duotone fa-circle-xmark" /></StatusLabel></Tooltip>
		}
		if(type == 'offPaidHoliDays') {
			return <Tooltip title="Nghỉ phép (phép năm)"><StatusLabel background type="red">{Icon.close}</StatusLabel></Tooltip>
		}

		if(type == 'workOnline') {
			return <Tooltip title="Làm việc online"><StatusLabel bold type="blue"><FontAwesomeIcon icon="fa-light fa-laptop-mobile" /></StatusLabel></Tooltip>
		}

		if(type == 'halfOnlineHalfWork') {
			return <Tooltip title="Làm việc online nữa ngày, nữa ngày làm ở công ty"><StatusLabel bold type="blue"><FontAwesomeIcon icon="fa-duotone fa-laptop-mobile" /></StatusLabel></Tooltip>
		}

		if(type == 'halfOnlineHalfOff') {
			return <Tooltip title="Làm việc online nữa ngày, Nghỉ nữa ngày"><StatusLabel bold type="blue"><FontAwesomeIcon icon="fa-light fa-laptop-slash" /></StatusLabel></Tooltip>
		}

		if(type == 'halfOnlineHalfOffNo') {
			return <Tooltip title="Làm việc online nữa ngày, Nghỉ nữa ngày không phép"><StatusLabel bold type="blue"><FontAwesomeIcon icon="fa-light fa-laptop-slash" /></StatusLabel></Tooltip>
		}

		if(type == 'halfOnlineHalfOffHoliDays') {
			return <Tooltip title="Làm việc online nữa ngày, Nghỉ nữa ngày (phép năm)"><StatusLabel bold type="blue"><FontAwesomeIcon icon="fa-light fa-laptop-slash" /></StatusLabel></Tooltip>
		}

		if(type == 'holiday') {
			return <Tooltip title="Nghỉ lễ"><StatusLabel bold type="purple"><FontAwesomeIcon icon="fa-light fa-gifts" /></StatusLabel></Tooltip>
		}

		return null
	}

	const handleTimeKeepingClick = (item) => {
		setItemEdit(item);
		setOpenModal(((prevState) => ({
			...prevState, timeKeeping: true
		})));
	}

	let columns = [
		{ title: 'Nhân viên', dataIndex: 'fullname', key: "fullname", fixed: true, width: '250px', render: (_, item) => (
			<div className="d-flex gap align-items">
				<div className="avatar"><Image src={item?.avatar} /></div>
				<div>
					<b>{item?.firstname + ' ' + item?.lastname}</b>
					<p>{item?.username}</p>
					{isMobile && <StatusLabel small type={utilities.roles[item.role]?.color}>{utilities.roles[item.role]?.title}</StatusLabel>}
				</div>
			</div>
		)},
		{ title: 'Chức vụ', dataIndex: 'role', key: "role", width: '200px', render: (_, item) => (<StatusLabel small type={utilities.roles[item.role]?.color}>{utilities.roles[item.role]?.title}</StatusLabel>) },

	];

	let date = dayjs();

	if(filter?.time) {
		let time = new Date(filter?.time*1000);
		if(time instanceof Date) {
			date = date.date(1)
			date = date.month(time.getMonth())
			date = date.year(time.getFullYear())
		}
	}

	const lastDay = date.endOf('month');

	const month = date.get('month') + 1;

	for(let day = 1; day <= lastDay.get('date'); day++) {
		let newDate = date.date(day);
		let dayOfWeek = newDate.day();
		if(dayOfWeek == 0) dayOfWeek = 'CN';
		if(dayOfWeek == 1) dayOfWeek = 'T2';
		if(dayOfWeek == 2) dayOfWeek = 'T3';
		if(dayOfWeek == 3) dayOfWeek = 'T4';
		if(dayOfWeek == 4) dayOfWeek = 'T5';
		if(dayOfWeek == 5) dayOfWeek = 'T6';
		if(dayOfWeek == 6) dayOfWeek = 'T7';
		columns.push({
			title: () => {
				return <>{dayOfWeek} <br />{day}/{month}</>
			},
			dataIndex: `day${day}_${month}`,
			key: `day${day}_${month}`,
			width: '70px',
			render: (_, item) => (
				renderIconWorking(item.details[day])
			)
		})
	}
	columns.push({ title: 'Trể / Sớm', dataIndex: 'goLate', key: "goLate", fixed: 'right',width: '70px', render: (_, item) => (
		renderNumberWorking(item?.working.goLate*1 + item?.working.goSoon*1)
	)})
	columns.push({ title: 'Online', dataIndex: 'online', key: "online", fixed: 'right',width: '75px', render: (_, item) => (
		renderNumberWorking(item?.totalOnline)
	)})
	columns.push({ title: 'Nghỉ', dataIndex: 'dayOff', key: "dayOff", fixed: 'right',width: '65px', render: (_, item) => (
		renderNumberWorking(
			(item?.working.dayOff*1 + item.working.dayOffNo*1 +
				(item?.working.halfDayOff*1 + item.working.halfDayOffNo*1
					+ item?.working.halfOnlineHalfOff*1 + item.working.halfOnlineHalfOffNo*1 + item.working.halfDayOffHoliDayHalfDayOff)/2))
	)})
	columns.push({ title: 'Phép năm', dataIndex: 'offPaidHoliDays', key: "offPaidHoliDays", fixed: 'right', width: '65px', render: (_, item) => (
		renderNumberWorking(item?.working.halfPaidHoliDays/2 + item?.working.halfOnlineHalfOffHoliDays/2 + item?.working.halfDayOffHoliDayHalfDayOff/2 + item?.working.offPaidHoliDays*1)
	)})
	columns.push({ title: 'Thử việc', dataIndex: 'probation', fixed: 'right', width: '70px', key: "probation", render: (_, item) => (
		renderNumberWorking(item?.probation)
	)})
	columns.push({ title: 'Ngày công', dataIndex: 'workday', fixed: 'right', width: '65px', key: "workday", render: (_, item) => (
		item?.totalWork + '/' + item?.totalDay
	)})
	columns.push({ title: '#', dataIndex: 'action', key: "action", fixed: 'right', width: '60px', render: (_, item) => (
		<WorkingDayAction
			item={item}
			onClickTimeKeeping={handleTimeKeepingClick}
		/>
	)})

	if(isMobile) {
		columns = columns.filter(function (currentValue) {
			return (currentValue.key != 'role'
				&& currentValue.key != 'halfDayOff'
				&& currentValue.key != 'dayOff'
				&& currentValue.key != 'goLate'
				&& currentValue.key != 'totalOff'
			)
		})
	}
	else {
		columns = columns.filter(function (currentValue) {
			return (currentValue.key != 'workingDay')
		})
	}

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false}/>
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default memo(WorkingDayTable);